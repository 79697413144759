<template>
  <div>
    <app-header />

    <div
      class="
        uk-section uk-padding-remove-vertical uk-margin-remove-vertical
        in-equity-breadcrumb
      "
    >
      <div class="uk-container">
        <div class="uk-grid">
          <div class="uk-width-1-1">
            <ul class="uk-breadcrumb">
              <li>
                <router-link :to="{ name: 'public.home' }">Home</router-link>
              </li>
              <li><span>Recover Account</span></li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        uk-section uk-section-muted uk-flex uk-flex-middle uk-animation-fade
      "
      style="padding-top; 0"
      uk-height-viewport
    >
      <div class="uk-width-1-1" style="margin-top: -100px">
        <div class="uk-container">
          <div class="uk-grid-margin uk-grid uk-grid-stack" uk-grid>
            <div class="uk-width-1-1@m" v-if="! email_sent">
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
              >
                <h3 class="uk-card-title uk-text-center">Forgot Your Password?</h3>
                <div class="uk-alert-danger" uk-alert v-if="has_error">
                  <a class="uk-alert-close" uk-close href="#"></a>
                  <p>Invalid email</p>
                </div>
                <form @submit.prevent="submit">
                  <div class="uk-margin">
                    <label for="">Email</label>
                    <div class="uk-inline uk-width-1-1">
                      <span class="uk-form-icon"
                        ><i
                          class="fa fa-envelope"
                          style="margin-right: 20px"
                        ></i
                      ></span>
                      <input
                        class="uk-input uk-form-large"
                        type="text"
                        required
                        v-model="user.email"
                      />
                    </div>
                  </div>
                  <div class="uk-margin">
                    <button
                      class="
                        uk-button uk-button-primary uk-button-large uk-width-1-1
                      "
                    >
                      Recover My Account
                    </button>
                  </div>
                  <div class="uk-text-small uk-text-center">
                    <router-link :to="{ name: 'register' }"
                      >Don't have an account? Create one</router-link
                    >
                  </div>
                </form>
              </div>
            </div>
            <div class="uk-width-1-1@m" v-if="email_sent">
              <div
                class="
                  uk-margin
                  uk-width-large
                  uk-margin-auto
                  uk-card
                  uk-card-default
                  uk-card-body
                  uk-box-shadow-large
                "
              >
                <h2>Recovery Email Sent</h2>
                <p>We have sent you an email with instructions on how to recover your account.</p>
                <p class="text-center">
                  <router-link :to="{ name: 'public.home' }" class="uk-button uk-button-primary uk-button-large uk-width-1-1"><i class="fa fa-home"></i> Okay</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <app-footer />
  </div>
</template>

<script>
import AppHeader from "../components/AppHeader";
import AppFooter from "../components/AppFooter";

export default {
  components: {
    AppHeader,
    AppFooter,
  },

  data() {
    return {
      user: {
        email: "",
      },
      email_sent: false,
      has_error: false
    };
  },

  methods: {
    submit() {
      this.$loader.start('full')
      this.has_error = false
      this.$axios.post("/api/v1/reset-account", this.user).then(() => {
        this.email_sent = true
        this.$loader.stop()
      }).catch(() => {
        this.has_error = true
        this.$loader.stop()
      })
    },
  },
};
</script>
